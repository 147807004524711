<template>
  <div>
    <v-card class="mt-1 mx-2 mb-1 elevation-5 rounded-lg">
      <PotensiMainMap></PotensiMainMap>
      <div class="d-flex" v-if="jenis==='IPRO' ">
        <v-spacer></v-spacer>
        <v-btn class="mt-n10 mr-2 elevation-16 rounded-lg green--text text--darken-3 font-weight-bold color-gradient-gold" dark color="#FFDF00"><v-icon small color="red">mdi-diamond</v-icon>Investment Project Ready to Offer (I-PRO)</v-btn>
      </div>
    </v-card>

    <PotensiMainTable
        v-if="true"
        :jenis="jenis"
    ></PotensiMainTable>

    <InfoDialog
        :infoDialogMessage="infoDialogMessage"
        ref="infoDialog"
    ></InfoDialog>


 </div>
</template>

<script>

import PotensiMainMap from "@/components/public_potensi_kawasan/PotensiMainMap";
import PotensiMainTable from "@/components/public_potensi_kawasan/PotensiMainTable";
import InfoDialog from "@/components/utils/InfoDialog";

export default {
  name: 'PotensiMain',
  props:{
    jenis: String
  },
  components: {
    InfoDialog,
    PotensiMainTable,

    PotensiMainMap
  },
  data(){
    return {
      showInfo : true,
      infoDialogMessage: ''
    }
  },
  methods:{
    dialogInfoShow(){
      this.infoDialogMessage = 'hello bos oke deh'
      this.$refs.infoDialog.showDialog()
    },

  },
  mounted() {

  },


}
</script>

<style scoped>

.color-gradient-gold{
  background-image: linear-gradient(to bottom, #f8e0c4 10%, transparent 90%);
}

a {
  background-image: linear-gradient(
      to right,
      #54b3d6,
      #54b3d6 50%,
      #000 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

a:before{
  content: '';
  background: #54b3d6;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

a:hover {
  background-position: 0;
}

a:hover::before{
  width: 100%;
}

/* Presentational Styles */
body {
  display: grid;
  font-family: 'Poppins', sans-serif;
  font-size: 27px;
  font-weight: 700;
  height: 100vh;
  place-items: center;
}

</style>