export default class FPotensiFilter {
  constructor(
      id = 0,
      pageNo= 0,
      pageSize = 10,
      sortBy="id",
      order="DESC",
      search="",

      jenis="",
      city="",

      listJenis =[],

      fdivisionIds =[],
      fareaIds=[],
      fsectorTypeIds=[],
      fkomoditiTypeIds=[]

  ) {
    this.id = id;
    this.pageNo = pageNo;
    this.pageSize = pageSize;
    this.sortBy = sortBy;
    this.order = order;
    this.search = search;

    this.jenis = jenis;
    this.city = city;

    this.listJenis = listJenis;
    this.fdivisionIds = fdivisionIds;
    this.fareaIds = fareaIds;
    this.fsectorTypeIds = fsectorTypeIds;
    this.fkomoditiTypeIds = fkomoditiTypeIds;
  }

}
