<template>
  <v-card class="elevation-0 mt-4 service bg-light section text-xs-center">

    <v-card-text>

      <v-row no-gutters class="ml-10 mr-10 justify-center">
        <v-col class="py-1" cols="12" sm="6" md="2">
          <v-text-field
              v-on:keyup.enter="searchOnEnter"
              v-on:blur="searchOnEnter"
              append-icon="mdi-magnify"
              outlined
              dense
              :hint="$t('hint')"
              :label="$t('cari')"
              class="gradient-textfield-bgcolor"
              rounded
              hide-details
          ></v-text-field>
        </v-col>
        <v-col class="py-1" cols="12" sm="6" md="2">
          <v-autocomplete
              v-model="filterFareaBean"
              :items="itemsFArea"
              item-value="id"
              item-text="description"
              outlined
              dense
              chips
              small-chips
              :label="$t('kabupatenKota')"
              class="ml-1 gradient-textfield-bgcolor"
              multiple
              clearable
              rounded
              hide-details
          ></v-autocomplete>
        </v-col>
        <v-col class="py-1" cols="12" sm="6" md="2"
               v-if="jenis==='IPRO' || jenis==='POTENSI' || jenis==='PELUANG'"
        >
          <v-autocomplete
              v-model="filterFsectorTypeBean"
              :items="itemsFSectorType"
              item-value="id"
              :item-text="lang==='id'? 'description' : 'descriptionEn'"
              outlined
              dense
              chips
              small-chips
              :label="$t('sektor')"
              class="ml-1 gradient-textfield-bgcolor"
              multiple
              clearable
              rounded
              hide-details
          ></v-autocomplete>
        </v-col>
        <v-col class="py-1" cols="12" sm="6" md="2"
               v-if="jenis==='IPRO' || jenis==='POTENSI' || jenis==='PELUANG'"
        >
          <v-autocomplete
              v-model="filterFkomoditiTypeBean"
              :items="itemsFKomoditiType"
              item-value="id"
              :item-text="lang==='id'? 'description' : 'descriptionEn'"
              outlined
              dense
              chips
              small-chips
              :label="$t('komoditi')"
              class="ml-1 gradient-textfield-bgcolor"
              multiple
              clearable
              rounded
              hide-details
          ></v-autocomplete>
        </v-col>

        <v-col class="py-1" cols="12" sm="2" md="2">
          <v-btn
          class="pl-8 pr-8 ml-2"
          color="primary"
          @click="searchOnClick"
          rounded
          >
            <v-icon small>mdi-magnify</v-icon>
            {{$t('cari')}}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="py-4" v-if="fPotensiFiltered.length < 1" align="center">
        <v-col cols="12" align="center">
          <span>{{lang==='id'? 'Data Tidak Tersedia' : 'No Data Available'}}</span>
        </v-col>
      </v-row>
      <v-row class="ml-8" v-if="false">
        <v-col cols="12" xs="12" sm="6" md="4" lg="3" class="d-flex child-flex"
               v-for="(item, i) in fPotensiFiltered"  :key="i"
        >
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                  max-width="320"
                  :elevation="hover? 12:4 "
                  class="rounded-lg"
              >

                <v-img
                    height="200"
                    :lazy-src="lookupImageUrlLazy(item)"
                    :src="lookupImageUrl(item)"
                    class="rounded-lg"
                >
                  <v-card-title>
                    <v-btn color="primary" class="rotating-45">PRO</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="rotating-45 mt-4 ml-16 pl-10">PRO</v-btn>
                  </v-card-title>

                </v-img>

                <v-card-text>
                  <div class="my-2 subtitle-2 font-weight-bold" v-if="lang==='id'">
                    {{item.kode2}}
                  </div>
                  <div class="my-2 subtitle-2 font-weight-bold" v-if="lang==='en'">
                    {{item.kode2En}}
                  </div>
                  <v-flex class="d-flex mt-1">
                    <span>{{ item.district }}, {{item.city}}</span>
                  </v-flex>
                  <v-flex>
                    <span class="caption">Tahun: {{ item.tahun }}</span>
                  </v-flex>
                </v-card-text>

              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>

      <v-layout wrap justify-center class="d-flex mt-2">
        <div  v-for="(item, i) in fPotensiFiltered" :key="i">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                  max-width="320"
                  xs12 sm4 px-3
                  class="ma-3 rounded-lg"
                  :elevation="hover? 16: 3"
              >
                <div  v-if="item.jenis==='IPRO' || item.jenis==='PELUANG' || item.jenis==='POTENSI'">
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <router-link style="text-decoration: none" :to="`public-potensi-detail/` + linkNeated(item.id.toString())">
                        <v-img
                            height="200"
                            :lazy-src="lookupImageUrlLazy(item)"
                            :src="lookupImageUrl(item)"
                            :class="{ 'hover-image': hover }"
                            class="rounded-lg"
                        >
                          <v-flex class="d-flex child-flex" >
                            <v-spacer></v-spacer>
                            <v-btn v-if="item.jenis==='IPRO'"  elevation="10" color="primary" width="20" height="35" class="rotating-45 mr-n16 font-weight-black"><span class="ml-10">IPRO</span></v-btn>
                            <v-btn v-else-if="item.jenis==='PELUANG'"  elevation="10" color="success" width="20" height="30" class="rotating-45 mr-n16"><span class="ml-10 caption">{{$t('peluang')}}</span></v-btn>
                            <v-btn v-else  elevation="0" color="green lighten-2" width="20" height="30" class="rotating-45 mr-n16"><span class="ml-10 caption font-weight-bold white--text">{{ lang === 'id' ? item.jenis : $t('potensi')}}</span></v-btn>
                          </v-flex>

                        </v-img>
                      </router-link>
                    </template>
                  </v-hover>
                </div>

                <div v-else>
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <router-link style="text-decoration: none" :to="`public-kawasan-detail/` + linkNeated(item.id.toString())">
                        <v-img
                            height="200"
                            :lazy-src="lookupImageUrlLazy(item)"
                            :src="lookupImageUrl(item)"
                            :class="{ 'hover-image': hover }"
                            class="rounded-lg"
                        >
                          <v-flex class="d-flex child-flex" >
                            <v-spacer></v-spacer>
                            <v-btn v-if="item.jenis==='IPRO' "  elevation="10" color="primary" width="20" height="35" class="rotating-45 mr-n16 font-weight-black"><span class="ml-10">IPRO</span></v-btn>
                            <v-btn v-else-if="item.jenis==='PELUANG'"  elevation="10" color="success" width="20" height="30" class="rotating-45 mr-n16"><span class="ml-10 caption">{{$t('peluang')}}</span></v-btn>
                            <v-btn v-else  elevation="0" color="green lighten-2" width="20" height="30" class="rotating-45 mr-n16"><span class="ml-10 caption font-weight-bold white--text">{{ lang === 'id' ? item.jenis : $t('potensi')}}</span></v-btn>
                          </v-flex>

                        </v-img>
                      </router-link>
                    </template>
                  </v-hover>
                </div>

                <v-flex class="d-flex">
                  <v-btn  v-if="lookupFSectorType(item.fsectorTypeBean).id>0 " dark class="ml-1 mt-n3 rounded-lg" x-small depressed color="rgb(104, 96, 223, 0.8)" elevation="3" >{{lang==='id'? lookupFSectorType(item.fsectorTypeBean).description : lookupFSectorType(item.fsectorTypeBean).descriptionEn }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn  v-if="item.remarkBool1 ==true " dark class="ml-1 mt-n3 mr-1 rounded-lg" x-small depressed color="rgb(252, 3, 36, 0.8)" elevation="3" >Perpres-80</v-btn>
<!--                  Perpress 80 tahun 2019-->
                </v-flex>

                <v-card-text>
                  <div class="subtitle-2" v-if="lang=='id'">
                    {{item.kode2}}
                  </div>
                  <div class="subtitle-2" v-if="lang=='en'">
                    {{item.kode2En}}
                  </div>

                  <v-flex class="d-flex mt-1">
                    <span class="caption font-weight-light">{{ item.district }}, {{item.city}}</span>
                  </v-flex>

                  <v-flex class="d-flex child-flex mt-1">
                    <div class="d-flex align-baseline">
                      <v-icon x-small >mdi-tag</v-icon>
                      <span class="caption ml-1">{{ formatNumberString(item.nilaiInvestasi) }}</span>
                    </div>
                    <div class="d-flex align-baseline">
                      <v-icon x-small>mdi-calendar-range</v-icon>
                      <span class="caption ml-1">{{ item.tahun }}</span>
                    </div>
                    <div class="d-flex align-baseline">
                      <span v-if="item.remarkBool2">
                        <v-icon x-small color="orange">mdi-map</v-icon>
                        <span class="caption ml-1">{{$t('terpetakan')}}</span>
                      </span>
                    </div>
                  </v-flex>

                </v-card-text>

              </v-card>
            </template>
          </v-hover>
        </div>

      </v-layout>

      <v-container>
        <v-row justify="center" align="center">
          <v-col
              cols="10"
              md="9"
              sm="8"
              align="right"
          >
            <v-pagination
                v-model="currentPage"
                :length="totalPaginationPages"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-container>

    </v-card-text>

    <PublicFooter></PublicFooter>

  </v-card>
</template>

<script>

import FPotensiService from '@/services/apiservices/f-potensi-service';
import FileService from "@/services/apiservices/file-service"

import FDivision from '@/models/f-division'

import {format, parseISO} from "date-fns";
import PublicFooter from "@/components/PublicFooter";
import FSectorTypeService from "@/services/apiservices/f-sector-type-service";
import FKomoditiTypeService from "@/services/apiservices/f-komoditi-type-service";
import FAreaService from "@/services/apiservices/f-area-service";
import FInfrastructureTypeService from "@/services/apiservices/f-infrastructure-type-service";
import FPotensiFilter from "@/models/payload/f-potensi-filter";
import FSubAreaService from "@/services/apiservices/f-sub-area-service";

export default {
  name: 'PotensiMainTable',
  props:{
    jenis: String
  },
  components: {
    PublicFooter
  },
  data () {
    return {
      snackbar: false,
      snackBarMesage: '',

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 24,
      pageSizes: [9, 25, 50, 150, 500],

      search: '',
      filterFareaBean: '',
      filterFsectorTypeBean: '',
      filterFkomoditiTypeBean: '',
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'coverImage',
          width: '20%'
        },
        { text: '', value: 'title', width:'30%' },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fPotensies:[],
      itemsFDivision: [
        new FDivision()
      ],
      itemsKabKota:[
        'Kota Surabaya', 'Kab Sidoarjo', 'Kab Mojokerto', 'Kota Malang', 'Kab Malang', 'Kota Kediri', 'Kab Kediri', 'Kab Lamongan'
      ],
    }
  },
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFPotensi()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage===1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFPotensi()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },
  computed: {
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    fPotensiFiltered(){

      let potensiesModified =[]
      const listPotensi = this.$store.state.potensi.itemsFPotensi
      for (let i=0; i< listPotensi.length; i++){
        let itemBerita = listPotensi[i];
        if (itemBerita.contentBody !==undefined){
          if (itemBerita.contentBody.length>100){
            itemBerita.contentBody = itemBerita.contentBody.substr(0, 99)
          }
        }
        potensiesModified.push(itemBerita)
      }

      return potensiesModified;
    },

    itemsFSectorType(){
      return this.$store.state.potensi.itemsFSectorType
    },
    itemsFKomoditiType(){
      return this.$store.state.potensi.itemsFKomoditiType
    },
    itemsFArea(){
      return this.$store.state.potensi.itemsFArea
    }

  },
  methods: {

    searchOnClick() {
        // console.log(`${event.target.value} vs ${this.search}`)

        this.currentPage = 1
        this.fetchFPotensi()

    },
    searchOnEnter(event){

      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value

        // console.log(JSON.stringify(this.filterFsectorTypeBean))
        // console.log(JSON.stringify(this.filterFkomoditiTypeBean))

        this.fetchFPotensi()
      }
    },
    fetchParent(){

      FSectorTypeService.getAllFSectorTypePublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFSectorType', response.data)
          },
          error => {
            console.log(error.response)
          }
      )
      FKomoditiTypeService.getAllFKomoditiTypePublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFKomoditiType', response.data)
          },
          error => {
            console.log(error.response)
          }
      )
      FAreaService.getAllFAreaPublic().then(
          response => {
            // console.log(JSON.stringify(response.data))
            // this.itemsFKomoditiType = response.data
            this.$store.dispatch('potensi/updateItemsFArea', response.data)
          },
          error => {
            console.log(error.response)
          }
      )
      FSubAreaService.getAllFSubAreaPublic().then(
          response => {
            // console.log(JSON.stringify(response.data))
            // this.itemsFSubArea = response.data
            this.$store.dispatch("potensi/updateItemsFSubArea", response.data)
          }
      )

      /**
       * NANTI DIPINDAH DENGA KONSEP PEMBATASAN BIAR LOADING TIDAK LAMA
       */
      FInfrastructureTypeService.getAllFInfrastructureTypePublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFInfrastructureType', response.data)
          },
          error => {
            console.log(error.response)
          }
      )


    },

    fetchFPotensi() {
      this.$store.dispatch('potensi/updateItemsFPotensi', [])

      // console.log(this.filterFareaBean)
      // console.log(this.filterFsectorTypeBean)
      // console.log(this.filterFkomoditiTypeBean)

      const fpotensiFilter = new FPotensiFilter()

      fpotensiFilter.pageNo = this.currentPage
      fpotensiFilter.pageSize = this.pageSize
      fpotensiFilter.search = this.search

      fpotensiFilter.jenis = this.jenis

      fpotensiFilter.fareaIds = this.filterFareaBean
      fpotensiFilter.fsectorTypeIds = this.filterFsectorTypeBean
      fpotensiFilter.fkomoditiTypeIds = this.filterFkomoditiTypeBean

      if (fpotensiFilter.fareaIds.length ===0) fpotensiFilter.fareaIds = []
      if (fpotensiFilter.fsectorTypeIds.length ===0) fpotensiFilter.fsectorTypeIds = []
      if (fpotensiFilter.fkomoditiTypeIds.length ===0) fpotensiFilter.fkomoditiTypeIds = []

      FPotensiService.getAllFPotensiContainingPublicWithJenisExt(fpotensiFilter).then(
          response => {

            const { items, totalPages} = response.data
            this.$store.dispatch('potensi/updateItemsFPotensi', items.filter(x=>x.statusActive))

            // console.log(JSON.stringify(items))

            this.totalPaginationPages = totalPages
            // console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
          },
          error => {
            console.log(error.response)
          }
      )

    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },
    lookupFSectorType (fsectorTypeBean) {
      const str = this.itemsFSectorType.filter(x => x.id===fsectorTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFKomoditiType (fkomoditiTypeBean) {
      const str = this.itemsFKomoditiType.filter(x => x.id===fkomoditiTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFArea (fareaBean) {
      const str = this.itemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    dateTimeFormat (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy hh:mm:ss') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      // return "Rp." + parseFloat(value).toFixed(0).toLocaleString()
      return prefix + "" + parseFloat(value).toLocaleString()
    },
    formatNumberString(value){
      let valueStr = ""
      if (value/1000000000 >1) {
        valueStr = `${parseFloat(value / 1000000000).toLocaleString()} Miliar`
      }else if(value/1000000>1){
        valueStr = `${parseFloat(value / 1000000).toLocaleString()} Juta`
      }else if(value/1000>1){
        valueStr = `${parseFloat(value / 1000).toLocaleString()} Ribu`
      }else{
        valueStr = value.toString()
      }
      return valueStr
    },

  },

  mounted() {
    this.fetchParent()
    if (this.jenis !== ''){
      console.log(this.jenis)

      this.fetchFPotensi()

    }
  }

}
</script>

<style scoped>
.rotating {
  transition: transform 1s ease-in-out;
}
.rotating:hover {
  transform: rotateZ(360deg);
}
.rotating-45{
  transform: rotateZ(45deg);
}


.hover-image{
  opacity: 0.6;
  cursor:pointer;
}



.gradient-textfield-bgcolor{
  background-image: linear-gradient(to top left, #bbd3ee 10%, transparent 80%);
}


</style>