import { render, staticRenderFns } from "./PotensiMainTable.vue?vue&type=template&id=32a5c5cc&scoped=true"
import script from "./PotensiMainTable.vue?vue&type=script&lang=js"
export * from "./PotensiMainTable.vue?vue&type=script&lang=js"
import style0 from "./PotensiMainTable.vue?vue&type=style&index=0&id=32a5c5cc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32a5c5cc",
  null
  
)

export default component.exports